import React, { useState } from "react";
import { FaFacebookF, FaYoutube, FaDiscord, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";

const Social = () => {
  const [showGmailImage, setShowGmailImage] = useState(false);
  const [showTiktokImage, setShowTiktokImage] = useState(false);
  const handleFacebookClick = () => {
    window.open(
      "https://web.facebook.com/profile.php?id=61564842124302",
      "_blank"
    );
  };

  const handleGmailClick = () => {
    window.open("mailto:support@justmayy.ai", "_blank");
  };

  const handleDiscordClick = () => {
    window.open("https://discord.gg/wfsS3PzdbF", "_blank");
  };

  const handleYoutubeClick = () => {
    window.open("https://www.youtube.com/@JustMayy-kon", "_blank");
  };

  const handleXClick = () => {
    window.open(
      "https://x.com/MayyJust81661?t=ptiimSHAojXHpxkR231vVQ&s=09",
      "_blank"
    );
  };

  const handleTiktokClick = () => {
    window.open(
      "https://www.tiktok.com/@just.mayy8?_t=8pI3K6Db0Aj&_r=1",
      "_blank"
    );
  };

  return (
    <div className="py-16 h-16 mx-auto overflow-hidden flex items-center justify-evenly space-x-4 text-white max-w-[400px] relative">
      <button
        onClick={handleFacebookClick}
        aria-label="Facebook"
        className="hover:text-[#1F7BF2] text-3xl"
      >
        <FaFacebookF />
      </button>
      <button
        onClick={handleYoutubeClick}
        aria-label="YouTube"
        className="hover:text-[#F60002] text-3xl"
      >
        <FaYoutube />
      </button>
      <div
        onMouseEnter={() => setShowGmailImage(true)}
        onMouseLeave={() => setShowGmailImage(false)}
        className="w-9"
      >
        <button
          onClick={handleGmailClick}
          aria-label="Email"
          className=" text-3xl"
          style={{ display: showGmailImage ? "none" : "block" }}
        >
          <SiGmail />
        </button>
        {/* The image that shows on hover */}
        {showGmailImage && (
          <button
            onClick={handleGmailClick}
            aria-label="Email"
            className=" text-3xl"
          >
            <img
              src="/Gmail_icon.svg.png"
              alt="GmailHoverImage"
              className=" w-8 h-6"
            />
          </button>
        )}
      </div>
      <button
        onClick={handleDiscordClick}
        aria-label="Discord"
        className="hover:text-[#5562EA] text-3xl"
      >
        <FaDiscord />
      </button>
      <button
        onClick={handleXClick}
        aria-label="X"
        className="hover:text-[#F7F7F7] text-3xl"
      >
        <FaXTwitter />
      </button>
      <div
        onMouseEnter={() => setShowTiktokImage(true)}
        onMouseLeave={() => setShowTiktokImage(false)}
        className="w-9"
      >
        <button
          onClick={handleTiktokClick}
          aria-label="TikTok"
          className="text-3xl"
          style={{ display: showTiktokImage ? "none" : "block" }}
        >
          <FaTiktok />
        </button>

        {/* The image that shows on hover */}
        {showTiktokImage && (
          <button
            onClick={handleTiktokClick}
            aria-label="TikTok"
            className=" text-3xl"
          >
            <img
              src="/Tiktok.webp"
              alt="TiktokHoverImage"
              className=" w-9 h-8"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Social;
