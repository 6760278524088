import React from "react";

const FeatureCard = ({ title, description, borderColor }) => {
  return (
    <div
      className={`group flex flex-col px-3 py-4 sm:px-4 sm:py-6 rounded-lg w-full border ${borderColor} transform transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:bg-[#FF4D05] hover:text-white select-none`}
    >
      <p className="whitespace-nowrap text-base sm:text-lg md:text-xl font-medium">
        {title}
      </p>
      <p className="text-[12px] sm:text-[14px] text-gray-400 mt-4 font-medium group-hover:text-white">
        {description}
      </p>
    </div>
  );
};

const Feature = () => {
  return (
    <div
      id="feature"
      className="pt-[120px] md:pt-[160px] px-4 sm:px-8 lg:px-16 xl:px-32 relative z-50"
    >
      <div className="text-center ">
        <p className="text-2xl sm:text-3xl lg:text-4xl xl:text-5xl font-bold">
          Features
        </p>
        <p className="text-lg sm:text-xl lg:text-xl mt-4">
          JustMayy offers the most comprehensive solution for your AI-powered
          assistance needs.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pt-4">
        <FeatureCard
          title="User-friendly"
          description="Navigate effortlessly with JustMayy’s intuitive interface, designed to provide a seamless experience across all devices."
          borderColor="border-[#FF4D05]"
        />
        <FeatureCard
          title="Prompt Guides"
          description="Get started quickly with helpful prompts and easy-to-follow guides. Let JustMayy show you the way!"
          borderColor="border-[#FF4D05]"
        />
        <FeatureCard
          title="Preferences"
          description="Customize your AI experience with tailored settings that fit your style. Make it truly yours with personalized preferences."
          borderColor="border-[#FF4D05]"
        />
        <FeatureCard
          title="Regular Updates"
          description="Benefit from frequent updates and new features that keep the platform fresh and efficient."
          borderColor="border-[#FF4D05]"
        />
        <FeatureCard
          title="Customer Loyalty"
          description="Foster lasting connections with great service. Offer rewards and personalized touches to keep customers returning. Build a community where they feel valued and appreciated."
          borderColor="border-[#FF4D05]"
        />
        <FeatureCard
          title="Customizable"
          description="Tailor the interface to suit your needs with flexible layout options."
          borderColor="border-[#FF4D05]"
        />
        <FeatureCard
          title="Community"
          description="Connect with others through forums, discussions, and user groups."
          borderColor="border-[#FF4D05]"
        />
        <FeatureCard
          title="24/7 Support"
          description="Access round-the-clock assistance for any questions or issues you may encounter."
          borderColor="border-[#FF4D05]"
        />
      </div>
    </div>
  );
};

export default Feature;
