import { motion } from "framer-motion";
import React from "react";
import StudentDiscountBanner from "./StudentDiscountBanner";

const PriceCard = ({ title, plan, description, price, bgColor }) => {
  return (
    <article
      className={`flex flex-col px-4 py-8 md:w-56 lg:w-72 ${bgColor} transform transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:bg-[#FF4D05] hover:text-white`}
      aria-label={`${title} plan`}
    >
      <p className="whitespace-nowrap text-3xl font-medium">{title}</p>
      <p className="text-3xl font-medium">{plan}</p>
      <p className="text-sm font-medium">{description}</p>
      <div className="mt-20">
        <p className="text-2xl font-medium">{price}</p>
        <button className="mt-2 whitespace-nowrap hover:cursor-not-allowed bg-white px-8 py-2 font-medium text-gray-900 transition-all hover:pr-10 hover:bg-gray-800 hover:text-white">
          Coming Soon
        </button>
      </div>
    </article>
  );
};

const Price = () => {
  return (
    <div
      id="pricing"
      className="container mx-auto pt-0 md:pt-10 lg:pt-24 relative z-50 "
    >
      <div className="grid px-4 lg:grid-cols-4">
        <div className="mt-16 mr-14 lg:col-span-1 ">
          <div className="flex h-fit items-end text-3xl font-semibold relative select-none">
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              src={"/logo_old.svg"}
              width={50}
              alt="logo"
            />
            <div className="flex items-end overflow-hidden">
              <motion.div
                initial={{ x: "-100%", width: 0 }}
                animate={{ x: 0, width: "auto" }}
                transition={{ delay: 0.5, type: "just", stiffness: 100 }}
                className="flex text-white items-end py-1"
              >
                ustMayy
              </motion.div>
            </div>
            <motion.span
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, type: "just", stiffness: 100 }}
              className="text-[50px] text-[#FF4D05] py-1"
            >
              .
            </motion.span>
          </div>
          <h2 className="mt-4 text-lg mb-2 ">
            is a budget-friendly, precise chatbot designed to assist you with
            complex topics or brainstorming and help bring your ideas to life.
            Its intuitive design makes it easy to integrate into your daily
            routine.
          </h2>
        </div>
        <div className="lg:col-span-3">
          {/* <div className="my-4 border-2 py-5 ">
            <p className="text-center font-medium">
              Are you a student?
              <span className="ml-2 whitespace-nowrap hover:cursor-not-allowed text-[#FF4D05]">
                Get student discounts
              </span>
            </p>
          </div> */}
          <StudentDiscountBanner />
          <div className="flex flex-col gap-4 md:flex-row md:justify-between">
            <PriceCard
              plan="Just Basic"
              description="Essentials at a great price"
              price="-"
              bgColor="bg-[#FF4D05]/20"
            />
            <PriceCard
              plan="Just Power"
              description="More power to you"
              price="-"
              bgColor="bg-[#FF4D05]/40"
            />
            <PriceCard
              plan="Just Extreme"
              description="Get the best in the market"
              price="-"
              bgColor="bg-[#FF4D05]/60"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
