import React, { useState } from "react";

const StudentDiscountBanner = () => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className="my-6 border-2 py-5">
      <p className="text-center font-medium">
        Are you a student?
        <span
          className="ml-2 whitespace-nowrap hover:cursor-not-allowed text-[#FF4D05] relative"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          Get student discounts
          {isHovering && (
            <span className="absolute left-1/2 -translate-x-1/2 bottom-full mb-7 px-2 py-1 text-sm text-white bg-[#ff4d05] rounded-md whitespace-nowrap">
              Coming Soon
            </span>
          )}
        </span>
      </p>
    </div>
  );
};

export default StudentDiscountBanner;
