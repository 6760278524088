import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
// import Login from "./components/Login";
// import SignUp from "./components/SignUp";
import "./";
// import { GoogleLogin } from "@react-oauth/google";

function App() {
  // const responseMessage = (response) => {
  //   console.log(response);
  // };
  // const errorMessage = (error) => {
  //   console.log(error);
  // };
  return (
    <div className="App bg-[#111111] min-h-screen">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} /> */}
      </Routes>
      {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
    </div>
    //
  );
}

export default App;
