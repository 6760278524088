// import { motion } from "framer-motion";
import React, { useEffect } from "react";

const Navigation = () => {
  const handleTryNowClick = () => {
    const url = "https://chat.justmayy.ai/?try=true";
    window.open(url, "_blank");
  };

  useEffect(() => {
    const smoothScroll = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute("href");
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener("click", smoothScroll);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", smoothScroll);
      });
    };
  }, []);

  return (
    <header className="max-h-[92px] z-[9999] relative">
      <div className="max-w-7xl mx-auto px-6 flex justify-between items-center">
        {/* <div className="flex h-fit items-end text-3xl font-semibold relative select-none">
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              src={"/logo_old.svg"}
              width={40}
              alt="logo"
              className=""
            />
            <div className="flex items-end overflow-hidden">
              <motion.div
                initial={{ x: "-100%", width: 0 }}
                animate={{ x: 0, width: "auto" }}
                transition={{ delay: 0.5, type: "just", stiffness: 100 }}
                className="flex text-white items-end py-1"
              >
                ustMayy
              </motion.div>
            </div>
            <motion.span
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, type: "just", stiffness: 100 }}
              className="text-[40px] text-[#FF4D05]"
            >
              .
            </motion.span>
          </div> */}
        <div>
          <img src="./logoNoBG.png" alt="Logo" className="h-24 w-24 z-10" />
        </div>
        <div>
          <div className="hidden md:flex items-center space-x-6 font-medium">
            <a
              href="#pricing"
              className="text-white hover:text-[#FF4D05] transition-colors duration-300"
            >
              Pricing
            </a>
            <a
              href="#feature"
              className="text-white hover:text-[#FF4D05] transition-colors duration-300"
            >
              Feature
            </a>
            <a
              href="#contact"
              className="text-white hover:text-[#FF4D05] transition-colors duration-300"
            >
              Contact
            </a>
          </div>
        </div>
        <div>
          <button
            className="border border-[#ff4d05] text-[#FF4D05] font-bold py-2 px-4 rounded-lg transition-transform duration-300 hover:scale-105 hover:bg-[#FF4D05] hover:text-white"
            onClick={handleTryNowClick}
          >
            Register
          </button>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
