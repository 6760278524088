import React from "react";

const Footer = () => {
  return (
    <footer class="relative my-10 px-4 pt-20">
      {/* <div class="absolute -top-10 left-1/2 h-16 w-16 -translate-x-1/2 rounded-xl border-4 border-[#ff4d05] bg-black p-2">
        <img class="h-full object-contain" src="/logoNOBG_1.png" alt="" />
      </div> */}
      {/* <nav
        aria-label="Footer Navigation"
        class="mx-auto mb-10 flex max-w-lg flex-col gap-10 text-center sm:flex-row sm:text-left"
      >
        <a href="#" class="font-medium text-white">
          Demo
        </a>
        <a href="#" class="font-medium text-white">
          Support
        </a>
        <a href="#" class="font-medium text-white">
          Privacy Policy
        </a>
        <a href="#" class="font-medium text-white">
          Terms & Conditions
        </a>
      </nav> */}
      <div className="flex justify-center items-center w-full px-4 py-2">
        {/* <p className="text-left text-gray-500">Powered by Knoectify</p> */}
        <p className="text-center text-gray-200">
          Innovation by Konectify. &emsp; © 2024 JustMayy. &emsp; All Rights
          Reserved.
        </p>
        {/* <p className="text-right text-gray-500">Powered by Knoectify</p> */}
      </div>
    </footer>
  );
};

export default Footer;
