import React from "react";

const ContactUs = () => {
  const handleGmailClick = () => {
    window.open("mailto:support@justmayy.ai", "_blank");
  };

  return (
    <div
      id="contact"
      className="border h-56 flex flex-col items-center justify-evenly"
    >
      <div>
        <h1 className="font-bold text-xl md:text-2xl text-neutral-300">
          Do You Have Question &#63;
        </h1>
        <div className="text-center pt-2 text-neutral-400">
          Feel Free to Contact us.
        </div>
      </div>
      <div>
        <button
          onClick={handleGmailClick}
          className="border border-[#FF4D05] text-[#FF4D05] font-bold px-6 py-3 rounded-lg transition-transform duration-300 hover:scale-105 hover:bg-[#FF4D05] hover:text-white"
        >
          Contact Us <span className="text-lg">&rarr;</span>
        </button>
      </div>
    </div>
  );
};

export default ContactUs;
