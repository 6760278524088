import React, { useState, useEffect } from "react";
import { Sparkles } from "lucide-react";
import { TypeAnimation } from "react-type-animation";
import DOMPurify from "dompurify";
import bgAnimation from "../desktopJM.gif";
import bgAnimationMobile from "../mobileJM.gif";
import logoBall from "../logoball.gif";

const sanitizeHTML = DOMPurify.sanitize;

const sentences = [
  "What is programming?",
  "How to build a website?",
  "Help me to solve this code...",
];

const Banner = () => {
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isFocused) {
        setIsAnimating(true);
        setTimeout(() => {
          setPlaceholderIndex(
            (prevIndex) => (prevIndex + 1) % sentences.length
          );
          setInputValue("");
          setIsAnimating(false);
        }, 200); // Half of the animation duration
      }
    }, 4000);

    return () => clearInterval(timer);
  }, [isFocused]);

  const handleInputChange = (e) => {
    setInputValue(sanitizeHTML(e.target.value));
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleSubmit = () => {
    window.open(`https://chat.justmayy.ai/?prompt=${inputValue}`, "_blank");
  };

  return (
    <div className="relative h-[calc(100svh-96px)] flex flex-col justify-between pb-10 items-center px-4">
      {/* <div
        style={{
          backgroundImage: `url(${bgAnimation})`,
          backgroundPosition: "center top -130px", // Custom background position
          backgroundSize: "cover",
        }}
        className="absolute top-0 left-0 w-screen h-full border z-10"
      ></div> */}

      {/* <div className="absolute w-full h-fit -top-[40px] md:-top-[100px] lg:-top-[160px] left-0 blur-sm">
        <img
          src={bgAnimation}
          alt="Background Animation"
          className="absolute top-0 left-0 w-full h-fit object-cover z-10 object-bottom"
        />
      </div> */}

      {/* for tablet and desktop view */}
      <div className="hidden md:block md:blur-sm lg:blur-none absolute inset-x-0 md:-top-[80px] lg:-top-[120px] pointer-events-none">
        <img
          src={bgAnimation}
          alt="AnimatedBackground"
          className="absolute inset-0 w-full h-auto object-cover z-10"
        />
      </div>

      {/* For mobile view */}
      <div className="block md:hidden pointer-events-none inset-x-0 absolute -top-28">
        <img
          src={bgAnimationMobile}
          alt="MobileBanner"
          className="absolute inset-0 blur-sm"
        />
      </div>

      <div>
        {/* pulse animation
        <div className="md:hidden absolute inset-0 w-fit z-10">
          <div className="blur-3xl opacity-30 absolute -top-48 -left-48 w-96 h-96 bg-orange-600 rounded-full animate-pulse"></div>
          <div className="blur-3xl opacity-30 absolute -bottom-20 -right-48 w-96 h-96 bg-orange-600 rounded-full animate-pulse"></div>
          <div className="blur-3xl opacity-30 absolute -bottom-44 left-1/2 w-64 md:w-96 h-80 bg-orange-600 rounded-full animate-pulse"></div>
        </div> */}
      </div>
      <div className="w-full" />
      <div className="flex z-50 flex-col justify-center items-center max-w-[380px] md:max-w-[480px] lg:max-w-[600px]">
        <div className="relative text-4xl text-white sm:text-5xl xl:text-6xl text-center font-bold mb-11 !leading-tight">
          <div className="absolute left-1/2 top-1/2 -translate-y-1/2 transform -translate-x-1/2 w-[160px] md:w-[180px] lg:w-[200px] pointer-events-none z-0 blur-sm">
            <img
              src={logoBall}
              alt="AnimatedBall"
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="relative z-10">
            Your answers are just <br /> an{" "}
            <span className="text-[#ff4d05]">ask</span> away.
          </div>
        </div>

        <div className="text-white text-center text-[12px] sm:text-sm md:text-lg">
          <TypeAnimation
            sequence={[
              "Ready for Anything – From answering complex questions to helping you pick your next movie, JustMayy’s got you covered.",
              1000,
            ]}
            wrapper="div"
            speed={70}
            cursor={false}
            className="inline-block"
          />
          {/* <img
            src="./popcornEmoji.png"
            alt="popcornEmoji"
            className="inline-block h-8 w-6 md:h-16 md:w-11"
          /> */}
        </div>
      </div>
      <div className="max-w-3xl w-full text-white z-50">
        <div className="relative">
          <form onSubmit={handleSubmit}>
            {/* Add form label */}
            <label htmlFor="chat"></label>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleFocus}
              className="w-full h-16 text-white bg-black border-2 border-[#ff4d05] focus:outline-none rounded-3xl py-3 px-6 pr-16"
            />
            <button type="submit" className="hidden" />
          </form>
          {!isFocused && (
            <div className="absolute left-6 top-1/2 transform -translate-y-1/2 pointer-events-none overflow-hidden h-8 flex items-center justify-center">
              <div
                className={`transform transition-transform duration-500 ${
                  isAnimating ? "-translate-y-full" : "translate-y-0"
                }`}
              >
                <div className="h-8 flex items-center justify-center text-[#B9B9B9]">
                  {sentences[placeholderIndex]}
                </div>
                {/* <div className="h-8">
                {sentences[(placeholderIndex + 1) % sentences.length]}
              </div> */}
              </div>
            </div>
          )}
          <a
            href={`https://chat.justmayy.ai/?prompt=${inputValue}`}
            target="_blank"
            rel="noreferrer"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-[#ff4d05] text-white rounded-2xl py-3 px-6 flex items-center space-x-3"
          >
            <Sparkles size={16} />
            <span className="font-semibold hidden md:block">Generate</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
