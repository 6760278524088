import Price from "./PriceCards";
import Feature from "./Features";
import ContactUs from "./ContactUs";
import Social from "./Social";
import Navigation from "./nav";
import Banner from "./Banner";
import Footer from "./Footer";

function Home() {
  return (
    <main>
      <Navigation />
      <Banner />
      <section className="max-w-6xl mx-auto p-4 text-white">
        <Price />
        <Feature />
        <Social />
        <ContactUs />
        <Footer />
      </section>
    </main>
  );
}
export default Home;
